import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
// layout
import DefaultLayout from '../layout'
// Components
import SearchHeader from '../layout/shared/searchHeader'
import Highlights from './highlights'
import UsersList from './usersList'
// services
import { getUsers } from '../../services/users'
import { getTokens } from '../../services/tokens'
// types
import { Token, ExtendedUser } from '../orders/interfaces'

const UsersContainer = styled.section`
  padding: calc(52px + 1rem) 2rem 1rem 2rem;

  h1 {
    font-size: 40px;
  }

  .download-button {
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
`

const Users = (): JSX.Element => {
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const [users, setUsers] = useState<ExtendedUser[]>([])
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [segmentFilter, setSegmentFilter] = useState<string>('')
  const [filteredUser, setFilteredUser] = useState<ExtendedUser[]>([])
  const [sortedUsers, setSortedUsers] = useState<ExtendedUser[]>([])

  useEffect(() => {
    const usersList = async (): Promise<void> => {
      const usersResult = await getUsers(userToken)
      const tokensResult = await getTokens(userToken)

      const completedUsers = usersResult.data.map((user: ExtendedUser) => {
        // eslint-disable-next-line no-param-reassign
        user.tokens = tokensResult.filter(
          (token: Token) => token.owner_rfc === user.rfc
        ).length
        return user
      })

      setUsers(completedUsers)
    }

    usersList()
  }, [userToken])

  useMemo(() => {
    // Search by name or email with searchbox
    if (users) {
      const filterResult = users.filter((user: ExtendedUser) => {
        const fullName = (user.name + user.last_name).toLocaleLowerCase().trim()
        const email = user.email.toLocaleLowerCase().trim()
        return (
          fullName.includes(searchFilter.toLocaleLowerCase().trim()) ||
          email.includes(searchFilter.toLocaleLowerCase().trim())
        )
      })
      setFilteredUser(filterResult)
    }
  }, [users, searchFilter])

  useMemo(() => {
    if (users) {
      const filterResult = users.filter((user) => {
        switch (segmentFilter) {
          case 'new-users':
            return !user.verificated
          case 'verificated-users':
            return user.verificated
          case 'pending-users':
            return (
              user.contract_status === 'toSign' ||
              user.contract_status === 'toCreate'
            )
          case 'signed-users':
            return user.contract_status === 'signed'
          default:
            return true
        }
      })
      setFilteredUser(filterResult)
    }
  }, [users, segmentFilter])

  useMemo(() => {
    if (users && segmentFilter === 'balance-users') {
      const sort = [...users].sort((a, b) => b.balance - a.balance)
      setSortedUsers(sort)
    }
  }, [users, segmentFilter])

  useMemo(() => {
    if (users && segmentFilter !== 'balance-users') {
      const sortedResult = filteredUser.sort(
        (a: ExtendedUser, b: ExtendedUser) => {
          const isAfter = moment(a.createdAt).isAfter(b.createdAt)
          if (isAfter) {
            return -1
          }
          if (!isAfter) {
            return 1
          }
          return 0
        }
      )
      setSortedUsers(sortedResult)
    }
  }, [users, filteredUser])

  const downloadCSV = (): void => {
    // Format data for CSV
    const csvData = users.map((user) => ({
      nombre: user.name,
      apellido: user.last_name,
      email: user.email,
      rfc: user.rfc,
      tokens: user.tokens,
      balance: user.balance,
      verificado: user.verificated ? 'Sí' : 'No',
      estado_contrato: user.contract_status || 'N/A',
      fecha_registro: moment(user.createdAt).format('DD/MM/YYYY')
    }))

    // Convert to CSV string
    const headers = Object.keys(csvData[0]).join(',')
    const rows = csvData.map((obj) => Object.values(obj).join(','))
    const csv = [headers, ...rows].join('\n')

    // Create and download file
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute(
      'download',
      `usuarios_${moment().format('YYYY-MM-DD')}.csv`
    )
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <DefaultLayout sidenav>
      <SearchHeader setSearchFilter={setSearchFilter} />
      <UsersContainer>
        <h1>Usuarios</h1>
        <button onClick={downloadCSV} className="download-button" type="button">
          Descargar CSV de Usuarios
        </button>
        <Highlights
          count={filteredUser.length}
          segmentFilter={segmentFilter}
          setSegmentFilter={setSegmentFilter}
        />
        {users && <UsersList users={sortedUsers} />}
      </UsersContainer>
    </DefaultLayout>
  )
}

export default Users
