import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// layout
import { colors } from '../constants'

const UrvitaInput = (props) => {
  const {
    autoComplete,
    labelText,
    name,
    type,
    inputWidth,
    onChange,
    value,
    errorType,
    error
  } = props

  const [inputValue, setInputValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState(error)

  useEffect(() => {
    if (errorType === '') {
      setErrorMessage('')
    } else if (errorType === 'empty input') {
      setErrorMessage('no puede estar vacío')
    }
  }, [errorType])

  const handleChange = (event) => {
    setInputValue(event.target.value)
    onChange(event)
  }

  return (
    <InputStyles inputWidth={inputWidth}>
      <label>{labelText}</label>
      <input
        autoComplete={autoComplete}
        name={name}
        type={type}
        onChange={handleChange}
        value={inputValue}
      />
      <span className="error">
        {errorMessage && inputValue === ''
          ? `${labelText} ${errorMessage}`
          : ''}
      </span>
    </InputStyles>
  )
}

const InputStyles = styled.div`
  width: ${(props) => props.inputWidth || '100%'};
  label {
    display: block;
    margin-top: 10px;
  }

  input {
    border: 2px solid ${colors.gridGray};
    border-radius: 4px;
    box-sizing: border-box;
    font-family: 'calibre';
    font-size: 16px;
    padding: 5px;
    width: 100%;

    :hover {
      filter: brightness(1.1);
    }

    :focus {
      border-color: ${colors.black};
      outline: none;
    }
  }

  .error {
    display: inline-block;
    color: red;
    height: 14px;
    font-size: 14px;
    width: 100%;
  }
`

const UrvitaSubmit = ({ text, error, loading }) => (
  <SubmitStyles>
    <input
      className={`loading-${loading}`}
      type="submit"
      value={loading ? 'Cargando...' : text}
      disabled={loading === true}
    />
    {error ? <span className="error">{error}</span> : null}
  </SubmitStyles>
)
const SubmitStyles = styled.div`
  margin: 40px auto;
  text-align: center;

  input {
    background-color: ${colors.secondaryColor};
    border: none;
    border-radius: 6px;
    color: ${colors.white};
    cursor: pointer;
    font-size: 16px;
    min-width: 280px;
    padding: 10px 15px;
    transition: all 0.4s;

    :hover {
      background-color: ${colors.highlightColor};
    }

    :active {
      transform: translateY(1px);
    }

    :focus {
      border-color: ${colors.black};
      outline: none;
    }

    &.loading-true {
      background-color: ${colors.gridGray};
    }
  }

  .error {
    display: inline-block;
    color: red;
    height: 14px;
    font-size: 1rem;
    text-align: center;
    width: 100%;
  }
`

export { UrvitaInput, UrvitaSubmit }
