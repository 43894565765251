import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import Modal from '../../layout/shared/modal' 
import Button from '../../layout/shared/button'
import { deleteTransaction } from '../../../services/transactions'
import { useSelector } from 'react-redux'
// helpers
import dateFormat from '../../helpers/dateFormat'
import translateTransactions from '../../helpers/translateTransactions'

const UserRow = ({
  id,
  rfc,
  name,
  lastName,
  userTransactions,
  userBuys,
  periodMonth,
  onUpdate
}) => {
  const userToken = useSelector((state) => state.user.token)
  const [sortedTransactions, setSortedTransactions] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const newUserTransactions = [...userTransactions, ...userBuys]
    const sorted = newUserTransactions.sort((a, b) => a.id - b.id)
    setSortedTransactions(sorted)
  }, [userTransactions, userBuys])

  const toggleDeleteModal = (value, transactionId = null) => {
    setDeleteModal(value)
    setSelectedId(transactionId)
  }

  const handleDelete = async (id) => {
    setLoading(true)
    try {
      await deleteTransaction(userToken, id)
      setDeleteModal(false)
      setSelectedId(null)
      if (onUpdate) onUpdate()
    } catch (error) {
      console.error('Error deleting transaction:', error)
    }
    setLoading(false)
  }

  return (
    <RowStyled>
      <h2 className="user-name">
        {id}.- {name} {lastName} <br /> {rfc}
      </h2>

      {sortedTransactions.length > 0 ? (
        <>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li
                key={transaction.id}
                className={
                  moment(transaction.transaction_date).format('MM') !==
                  periodMonth
                    ? 'nextMonth'
                    : ''
                }
              >
                <span>
                  {moment(transaction.transaction_date).format('DD ')}
                  {dateFormat(transaction.transaction_date, 'month')}
                </span>
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li key={transaction.id}>
                <span>{translateTransactions(transaction.type)}</span>
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li
                key={transaction.id}
                className={
                  transaction.indicator === 'withdrawal' ||
                  transaction.indicator === 'buy'
                    ? 'amount-column withdrawal'
                    : 'amount-column'
                }
              >
                <NumberFormat
                  value={Number(transaction.subtotal).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                  prefix={
                    transaction.indicator === 'withdrawal' ||
                    transaction.indicator === 'buy'
                      ? '-'
                      : ''
                  }
                />
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li
                key={transaction.id}
                className={
                  transaction.indicator === 'withdrawal' ||
                  transaction.indicator === 'buy'
                    ? 'amount-column withdrawal'
                    : 'amount-column'
                }
              >
                <NumberFormat
                  value={Number(transaction.net_amount).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                  prefix={
                    transaction.indicator === 'withdrawal' ||
                    transaction.indicator === 'buy'
                      ? '-'
                      : ''
                  }
                />
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li key={transaction.id} className="amount-column">
                <NumberFormat
                  value={Number(transaction.new_user_balance).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                />
                <span className="mono"><button className="delete-button" onClick={() => toggleDeleteModal(true, transaction.id)}>X</button></span>
              </li>
            ))}
          </ol>
        </>
      ) : (
        <span>-</span>
      )}

      {deleteModal && (
        <Modal toggleModal={toggleDeleteModal}>
          <h2>Borrar transacción</h2>
          <div className="transaction-details">
            <p><strong>Usuario:</strong> {sortedTransactions.find(t => t.id === selectedId)?.user.name} {sortedTransactions.find(t => t.id === selectedId)?.user.last_name}</p>
            <p><strong>Tipo:</strong> {translateTransactions(sortedTransactions.find(t => t.id === selectedId)?.type)}</p>
            <p><strong>Fecha:</strong> {moment(sortedTransactions.find(t => t.id === selectedId)?.transaction_date).format('DD MMM YY')}</p>
            <p><strong>Monto:</strong> <NumberFormat
              value={Number(sortedTransactions.find(t => t.id === selectedId)?.net_amount).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            /></p>
          </div>
          <p className="modal-text">
            Si borras esta transacción se eliminará permanentemente del registro.
          </p>
          <div className="button-container">
            < Button
              text={loading ? 'Eliminando...' : 'Eliminar transacción'}
              clickHandler={() => handleDelete(selectedId)}
            />
            <Button 
              text="Cancelar" 
              clickHandler={() => toggleDeleteModal(false)} 
            />
          </div>
        </Modal>
      )}
    </RowStyled>
  )
}

const RowStyled = styled.li`
  align-items: center;
  border-bottom: 2px solid black;
  display: grid;
  grid-template-columns: 300px 100px 280px 160px 160px 160px;
  padding: 10px 0;

  .user-name {
    font-size: 16px;
    font-weight: 500;
  }

  .user-transactions {
    list-style: none;
  }

  .amount-column {
    text-align: right;
    font-family: monospace;
  }

  .withdrawal {
    color: darkred;
  }

  .number-title {
    margin-right: 5px;
    font-weight: 500;
  }

  .nextMonth {
    text-decoration: line-through;
  }

  .delete-button {
    border: 1px solid red;
    border-radius: 8px;
    color: red;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    padding: 1px;
    margin: 0 5px;
    text-align: center;
    width: 20px;

    :hover {
      background-color: red;
      color: white;
    }
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-top: 10px;
  }

  .transaction-details {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    margin: 15px 0;
    
    p {
      margin: 5px 0;
    }
  }
`

export default UserRow
