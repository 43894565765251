import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { Transaction } from '../orders/interfaces'
// helpers
import translateTransactions from '../helpers/translateTransactions'
// service
import { deleteTransaction } from '../../services/transactions'
// Layout
import { colors } from '../layout/constants'
import Button from '../../urvita-ui/button'
import Modal from '../layout/shared/modal'

const RowStyled = styled.li`
  align-items: left;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.5fr 1.5fr 1fr 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-column-gap: 20px;
  padding: 5px 0;
  border-bottom: 1px solid ${colors.gridGray};

  .mono {
    font-family: monospace;
    text-align: right;
  }

  .gray {
    color: gray;
  }

  .withdrawal {
    color: red;
  }

  .delete-button {
    border: 1px solid red;
    border-radius: 8px;
    color: red;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    padding: 1px;
    text-align: center;
    width: 20px;

    :hover {
      background-color: red;
      color: white;
    }
  }

  .modal-text {
    max-width: 280px;
    margin: 10px auto;
  }

  .button-container {
    div {
      margin: 1rem 0;
    }
  }
`

const TransactionRow = ({
  transaction,
  handleUpdate
}: {
  transaction: Transaction
  handleUpdate: any
}): JSX.Element => {
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  // State Hooks
  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggleDeleteModal = (value: boolean): void => {
    setDeleteModal(value)
  }

  const removeTransaction = async (id: number): Promise<void> => {
    setLoading(true)
    try {
      await deleteTransaction(userToken, id)
    } catch (error) {
      console.error('error')
    }
    setLoading(false)
    setDeleteModal(false)
    handleUpdate()
  }

  return (
    <RowStyled>
      <span className="gray">{transaction.id}</span>
      <span>{moment(transaction.transaction_date).format('DD MMM YY')}</span>
      <span className="gray">
        {moment(transaction.createdAt).format('DD MMM YY')}
      </span>
      <span className="left">{translateTransactions(transaction.type)}</span>
      <NumberFormat
        className="mono"
        value={Number(transaction?.subtotal).toFixed(2)}
        displayType="text"
        thousandSeparator
        decimalScale={0}
      />
      <NumberFormat
        className="mono"
        value={Number(transaction?.iva).toFixed(2)}
        displayType="text"
        thousandSeparator
      />
      <NumberFormat
        className="mono"
        value={Number(transaction?.isr).toFixed(2)}
        displayType="text"
        thousandSeparator
        prefix="-"
      />
      <NumberFormat
        className="mono"
        value={Number(transaction?.retention).toFixed(2)}
        displayType="text"
        thousandSeparator
        prefix="-"
      />
      <NumberFormat
        className={
          transaction.indicator === 'withdrawal' ||
          transaction.indicator === 'buy'
            ? 'mono withdrawal'
            : 'mono'
        }
        value={Number(transaction.net_amount).toFixed(2)}
        displayType="text"
        thousandSeparator
        prefix={
          transaction.indicator === 'withdrawal' ||
          transaction.indicator === 'buy'
            ? '-'
            : ''
        }
        decimalScale={0}
      />
      <NumberFormat
        className={
          transaction?.new_user_balance < 0 ? 'mono withdrawal' : 'mono'
        }
        value={Number(transaction?.new_user_balance).toFixed(2)}
        displayType="text"
        thousandSeparator
        decimalScale={0}
      />
      <span className="center">
        {transaction.owner ? transaction.owner : '-'}
      </span>
      <span className="left">{transaction.note}</span>

      <button
        className="delete-button"
        type="button"
        onClick={() => toggleDeleteModal(true)}
      >
        X
      </button>

      {deleteModal && (
        <Modal toggleModal={toggleDeleteModal}>
          <h2>Borrar transacción</h2>

          <p className="modal-text">
            Si borras esta transacción se eliminará permanentemente del
            registro.
          </p>

          <div className="button-container">
            <Button
              text={loading ? 'Eliminando...' : 'Eliminar transacción'}
              onClick={() => removeTransaction(transaction.id)}
            />
            <Button text="Cancelar" onClick={() => toggleDeleteModal(false)} />
          </div>
        </Modal>
      )}
    </RowStyled>
  )
}

export default TransactionRow
