import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import NumberFormat from 'react-number-format'
// helpers
import dateFormat from '../../helpers/dateFormat'
import translateTransactions from '../../helpers/translateTransactions'

const UsersTable = ({ period, usersList, previewList, transactionsList }) => {
  // Filter transactions by user
  const filteredTransactions = (userRfc) => {
    const userTransactions = transactionsList.filter(
      (transaction) => transaction.user_rfc === userRfc
    )
    return userTransactions
  }

  return (
    <TableStyled>
      <li className="table-header">
        <h3>Nombre</h3>
        <h3>Operaciones de {dateFormat(period, 'month')}</h3>
        <h3>Operaciones a generar</h3>
        <h3>Posibles compras</h3>
      </li>

      {usersList && previewList
        ? previewList.map((user, index) => (
            <li key={index} className="table-row">
              {/* User info */}
              <div>
                <h3 className="user-name">
                  {user.userName} <br /> {user.userRfc}
                </h3>
              </div>

              {/* Current operations */}
              <ul>
                {filteredTransactions(user.userRfc).map((transaction) => (
                  <li key={transaction.id} className="number-row">
                    <span>
                      {moment(transaction.transaction_date).format('DD ')}
                      {dateFormat(transaction.transaction_date, 'month')}
                    </span>
                    <span>{translateTransactions(transaction.type)}</span>
                    <NumberFormat
                      className="mono-number"
                      value={Number(transaction.net_amount).toFixed(2)}
                      displayType="text"
                      thousandSeparator
                      prefix={
                        transaction.indicator === 'withdrawal' ||
                        transaction.indicator === 'buy'
                          ? '-'
                          : ''
                      }
                    />
                  </li>
                ))}
              </ul>

              {/* Month operations */}
              <div>
                <p className="number-row">
                  Ganancias por Tokens:{' '}
                  <span className="mono-number">{user.tokenRents}</span>
                </p>
                <p className="number-row">
                  Ajuste por compra:{' '}
                  <span className="mono-number">{user.rentAdjustment}</span>
                </p>
                <p className="number-row">
                  Total de ganancias:{' '}
                  <span className="mono-number total">
                    {(
                      Number(user.totalTokenRent)
                    ).toFixed(2)}
                  </span>
                </p>
              </div>

              {/* Posible buys */}
              <div>
                {/* Resumen */}
                <p className="number-row">
                  Balance anteror{' '}
                  <NumberFormat
                    className="mono-number"
                    value={Number(
                      user.reinvestmentTokenBuy.userBalance
                    ).toFixed(2)}
                    displayType="text"
                    thousandSeparator
                  />
                </p>
                <p className="number-row">
                  Ganancias{' '}
                  <NumberFormat
                    className="mono-number"
                    value={(
                      Number(user.totalTokenRent)
                    ).toFixed(2)}
                    displayType="text"
                    thousandSeparator
                  />
                </p>
                <p className="number-row">
                  Nuevo Balance:{' '}
                  <NumberFormat
                    className="mono-number total"
                    value={(
                      Number(user.reinvestmentTokenBuy.userBalance) +
                      Number(user.totalTokenRent)
                    ).toFixed(2)}
                    displayType="text"
                    thousandSeparator
                  />
                </p>
              </div>
            </li>
          ))
        : null}
    </TableStyled>
  )
}

const TableStyled = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  .table-header {
    align-items: center;
    border-bottom: 2px solid black;
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr 1fr;
    padding: 10px 0;
    text-align: center;
  }

  .table-row {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 1.5fr 1fr 1fr;
    border-bottom: 1px solid black;
    padding: 0.5em 0;
  }

  .number-row {
    display: flex;
    justify-content: space-between;
  }

  .mono-number {
    font-family: monospace;
  }

  .total {
    font-weight: bold;
    border-top: 1px solid black;
  }

  .amount-column {
    text-align: right;
  }
`

export default UsersTable
